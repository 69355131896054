:root {
  --header-height: 90px;
  /* Update this value to change header's height */
  --footer-height: 227px !important;
  /* Update this value to change footer's height */ }

* {
  -webkit-print-color-adjust: exact; }

.has-white-color {
  color: #FFF; }

.has-light-blue-color {
  color: #5089a6; }

.has-light-green-color {
  color: #82b38b; }

.has-light-blue-background-color {
  background-color: #5089a6 !important; }

.has-blue-color {
  color: #5089a6; }

.has-blue-background-color {
  background-color: #5089a6; }

.has-gray-color {
  color: #66676d; }

.has-gray-border {
  border: 1px solid #EEE; }

.has-gray-background-color {
  background-color: #66676d; }

.has-dark-gray-color {
  color: #5c5d63; }

.has-dark-gray-background-color {
  color: #5c5d63; }

.has-green-color {
  color: #5baf95; }

.has-green-background-color {
  background-color: #5baf95; }

.has-red-color {
  color: #ba5555; }

.has-black-color {
  color: #000; }

.has-red-background-color {
  background-color: #ba5555; }

.has-ligth-green-color {
  color: #31ba1a; }

.has-ligth-green-background-color {
  background-color: #31ba1a; }

.has-mbd-dark-grey-color {
  color: #333333; }

.has-mbd-dark-grey-background-color {
  background-color: #333333; }

.has-mbd-light-grey-color {
  color: #FCFCFC; }

.has-mbd-light-grey-background-color {
  background-color: #FCFCFC; }

.has-mbd-dark-teal-color {
  color: #06494B; }

.has-mbd-dark-teal-background-color {
  background-color: #06494B; }

.has-mbd-medium-teal-color {
  color: #075A5C; }

.has-mbd-medium-teal-background-color {
  background-color: #075A5C; }

.has-mbd-light-teal-color {
  color: #63A1A1; }

.has-mbd-light-teal-background-color {
  background-color: #63A1A1; }

.has-mbd-accent-green-color {
  color: #9EB944; }

.has-mbd-accent-green-background-color {
  background-color: #9EB944; }

.has-dc-main-blue-color {
  color: #03579D; }

.has-dc-main-blue-background-color {
  background-color: #03579D; }

.has-dc-green-color {
  color: #76a234; }

.has-dc-green-background-color {
  background-color: #76a234; }

.has-dc-red-color {
  color: #A90000; }

.has-dc-red-background-color {
  background-color: #A90000; }

.has-dc-dark-grey-color {
  color: #2B2F31; }

.has-dc-dark-grey-background-color {
  background-color: #2B2F31; }

.has-dc-define-color {
  color: #355966; }

.has-dc-define-background-color {
  background-color: #355966; }

.has-dc-design-color {
  color: #3B8E8E; }

.has-dc-design-background-color {
  background-color: #3B8E8E; }

.has-dc-analysis-color {
  color: #88AE3F; }

.has-dc-analysis-background-color {
  background-color: #88AE3F; }

.has-dc-monitor-color {
  color: #F1592D; }

.has-dc-monitor-background-color {
  background-color: #F1592D; }

p {
  line-height: 1.5;
  font-weight: 400;
  font-size: 1rem; }

b, strong {
  font-weight: 700; }

h1 {
  color: #66676d;
  font-size: 30px; }

h2 {
  font-size: 1.9rem !important;
  color: #66676d; }

h3 {
  font-size: 1.3rem !important;
  color: #66676d; }

h4 {
  font-size: 1.07rem !important;
  color: #66676d; }

h5 {
  font-size: 1rem !important;
  color: #66676d; }

h6 {
  font-size: .7rem !important;
  color: #66676d; }

/* Styles */
.has-standard-14-font-size {
  font-size: 14px; }

.has-standard-12-font-size {
  font-size: 12px; }

.has-standard-14-font-size {
  font-size: 14px; }

.has-standard-15-font-size {
  font-size: 15px; }

.has-standard-16-font-size {
  font-size: 16px; }

.has-standard-18-font-size {
  font-size: 18px; }

.has-standard-24-font-size {
  font-size: 24px; }

.has-standard-27-font-size {
  font-size: 27px; }

.has-standard-30-font-size {
  font-size: 30px; }

.has-standard-36-font-size {
  font-size: 36px; }

.has-standard-64-font-size {
  font-size: 64px;
  line-height: 110%; }

.has-condensed-text {
  font-stretch: condensed;
  line-height: 115% !important; }

.has-weight-300 {
  font-weight: 300; }

.has-weight-400 {
  font-weight: 400; }

.has-weight-500 {
  font-weight: 500; }

.has-weight-600 {
  font-weight: 600; }

.has-weight-700 {
  font-weight: 700; }

.has-weight-800 {
  font-weight: 800; }

.has-weight-900 {
  font-weight: 900; }

:root {
  --header-height: 90px;
  /* Update this value to change header's height */
  --footer-height: 227px !important;
  /* Update this value to change footer's height */ }

.desktop {
  margin: 0 !important;
  width: 100%; }

.ui.container {
  width: 80%;
  margin-left: auto !important;
  margin-right: auto !important; }

.entry-content {
  width: 100%; }

.breadcrumbs {
  display: none; }

.wp-block-image.aligncenter {
  text-align: center;
  margin: auto; }

.font-title {
  font-family: "Merriweather", serif !important; }

@media (min-width: 1750px) {
  .dc-title {
    font-family: "Merriweather", serif !important;
    padding: 70px 0 0 0 !important;
    font-size: 95px !important;
    /* Increase font size for screens with width 768px and above */ }
  .dc-cover-subtitle {
    font-size: 39.5px !important; }
  .dc-spacer-cover {
    height: 820px !important; } }

@media (min-width: 1500px) and (max-width: 1750px) {
  .dc-title {
    font-family: "Merriweather", serif !important;
    padding: 25px 0 0 0 !important;
    font-size: 75px !important;
    /* Increase font size for screens with width 768px and above */ }
  .dc-cover-subtitle {
    font-size: 32px !important; }
  .dc-spacer-cover {
    height: 700px !important; } }

.cta-buttons {
  display: flex;
  justify-content: center;
  /* Center the buttons horizontally */
  align-items: center;
  /* Center the buttons vertically */ }
  .cta-buttons .cta-primary-button .wp-block-button__link {
    border: 1px solid #76a234;
    border-radius: 3px;
    color: white;
    width: 255px;
    margin: 0 50px 0 0;
    background-color: #76a234;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.3);
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
  .cta-buttons .cta-primary-button:hover .wp-block-button__link {
    transition: 471ms;
    transform: translateY(-5px);
    background-color: #60832a;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2); }
  .cta-buttons .cta-secondary-button .wp-block-button__link {
    border: 1px solid #76a234;
    border-radius: 3px;
    color: #76a234;
    width: 255px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
  .cta-buttons .cta-secondary-button:hover .wp-block-button__link {
    transition: 471ms;
    transform: translateY(-5px);
    color: #60832a;
    border: 1px solid #60832a;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2); }

.cta-buttons-internal {
  position: absolute;
  width: 100vw;
  left: 0; }
  .cta-buttons-internal .cta-secondary-button .wp-block-button__link {
    color: white; }
  .cta-buttons-internal .cta-secondary-button:hover .wp-block-button__link {
    color: white;
    border: 1px solid #60832a;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2); }

.wp-block-cover {
  padding: 150px 0 0 18vw;
  margin: 0;
  display: flex; }
  .wp-block-cover .wp-block-cover__inner-container {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: start;
    align-self: start; }
  .wp-block-cover .wp-block-separator {
    width: 537px;
    margin: 0; }
  .wp-block-cover .dc-cover-rotate {
    transform: rotate(-90deg) translateX(-20px);
    margin: 0 0 -120px -60px;
    height: 300px; }
    .wp-block-cover .dc-cover-rotate .wp-block-separator {
      width: 189px; }
  .wp-block-cover .dc-cover-paragraph {
    margin-left: -150px;
    padding: 0 18vw 0 0; }

.dc-who-section {
  padding: 0 18vw 0 18vw; }
  .dc-who-section .wp-block-separator {
    width: 537px;
    margin: 0; }

.container.header {
  height: var(--header-height);
  padding: 0px;
  margin: 0px;
  position: relative; }
  .container.header .ui.text.branding.menu {
    position: relative; }
  .container.header .brand.logo {
    display: block;
    height: 39px;
    cursor: pointer; }
  .container.header .ui.search {
    position: fixed;
    right: 30px;
    display: none; }
  .container.header .menu.pages {
    position: absolute;
    right: 1.7vw;
    font-size: 0.85rem;
    padding-top: 16px; }
    .container.header .menu.pages .item.divided {
      font-size: 0.85rem;
      font-weight: 600 !important;
      text-transform: lowercase;
      padding: 0 25px 0 25px; }
      .container.header .menu.pages .item.divided a {
        color: #76a234; }
      .container.header .menu.pages .item.divided a:hover {
        color: #03579D; }
    .container.header .menu.pages .item.divided.selected.active a {
      color: #03579D; }
  .container.header .menu, .container.header .branding {
    margin: 0;
    padding: 20px 0 0 30px;
    position: relative; }
    .container.header .menu .divider, .container.header .branding .divider {
      display: none; }
    .container.header .menu .flag, .container.header .branding .flag {
      display: none; }
    .container.header .menu .site.name, .container.header .branding .site.name {
      color: #03579D;
      font-size: 1rem;
      padding: 0 0 0 5px;
      font-weight: 800; }

.viz.tabbed.posts.container {
  margin: 0px;
  min-height: 250px;
  padding: 0 20px; }
  .viz.tabbed.posts.container .ui.container.entry-content {
    margin: 0px;
    padding: 25px 15px; }
  .viz.tabbed.posts.container .label {
    display: none; }

.ui.grid.tabbed.posts .list-icon {
  background-color: #2B2F31;
  width: 7px !important;
  height: 7px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-top: 10px; }

.ui.grid.tabbed.posts > .column {
  padding: 0; }
  .ui.grid.tabbed.posts > .column button.nav {
    height: 250px !important;
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border-radius: 0;
    border-bottom: solid 9px transparent; }
    .ui.grid.tabbed.posts > .column button.nav .ui.image.icon {
      height: 200px; }

.ui.grid.tabbed.posts .active button.nav {
  border-bottom: solid 9px #365967; }

.myths.list {
  display: flex;
  flex-direction: column; }
  .myths.list .header {
    padding: 0.5em;
    display: flex;
    flex-direction: row;
    margin-bottom: 2vh; }
    .myths.list .header h2 {
      margin: 0; }
    .myths.list .header img {
      margin-right: 10px;
      width: 40px;
      height: auto; }
  .myths.list .title {
    cursor: text;
    margin-left: 3.2em;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0; }
    .myths.list .title .entry-content {
      flex: 1;
      padding: 0;
      margin: 0; }
      .myths.list .title .entry-content p {
        font-size: 1.3em;
        font-weight: 400;
        line-height: 130%;
        margin-left: -65px;
        text-indent: 70px; }
    .myths.list .title .ui.label {
      width: 100px;
      border-radius: 0;
      margin: 0;
      font-weight: 400;
      color: #ba5555;
      font-size: 1.3em;
      line-height: 30px;
      padding: 0px 0.2em 0px 0px;
      border: none; }
      .myths.list .title .ui.label img {
        border: 1px solid transparent;
        display: inline-block;
        height: auto !important;
        margin: 0;
        width: 30px !important; }
  .myths.list > .content {
    display: flex !important;
    margin-bottom: 15px;
    margin-left: 3.2em;
    flex-direction: row;
    border-bottom: 2px solid #ececec !important;
    font-weight: 100;
    color: #5baf95;
    padding-bottom: 20px !important; }
    .myths.list > .content .excerpt {
      display: none; }
    .myths.list > .content p {
      font-size: 1.3em;
      font-weight: 400;
      line-height: 130%;
      margin-top: 0;
      margin-left: -70px;
      padding-bottom: 17px;
      padding-top: 0; }
      .myths.list > .content p:first-child {
        margin-left: -70px;
        text-indent: 70px; }
    .myths.list > .content .label {
      justify-content: center;
      color: #5baf95;
      align-items: center;
      font-size: 1.3em;
      font-weight: 400;
      border: 0;
      line-height: 30px;
      padding: 0 0.2em 0 0;
      height: 35px; }
      .myths.list > .content .label img {
        border: 1px solid transparent;
        display: inline-block;
        height: auto !important;
        margin: 0;
        width: 30px !important; }
    .myths.list > .content .entry-content {
      flex: 1;
      padding: 0;
      margin: 0; }
      .myths.list > .content .entry-content > p {
        padding: 0; }
  .myths.list > .title.odd {
    margin-top: 10px; }

/* iPads (portrait and landscape) ----------- */
/* Smartphones (portrait and landscape) ----------- */
/* Smartphones (portrait) ----------- */
/* Smartphones (portrait) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px), screen and (max-width: 320px) {
  .ui.container.section .menu,
  .ui.container.section .header {
    border: 0 !important; }
    .ui.container.section .menu .item,
    .ui.container.section .header .item {
      border: 0 !important;
      display: none; } }

:root {
  --header-height: 90px;
  /* Update this value to change header's height */
  --footer-height: 227px !important;
  /* Update this value to change footer's height */ }

.green .left.navigator {
  top: 10px;
  float: left;
  position: sticky !important;
  width: 100px;
  margin-left: -8vw; }
  .green .left.navigator .ui.menu {
    background-color: #5baf95;
    margin: 0;
    padding: 0;
    width: 90px;
    min-width: 70px;
    max-width: 100px;
    display: flex; }
    .green .left.navigator .ui.menu .header {
      font-size: 1.4vmin;
      align-content: center;
      text-align: center;
      text-transform: uppercase; }
    .green .left.navigator .ui.menu .item {
      border-bottom: 1px solid #fff;
      padding: 10% 1%;
      box-sizing: border-box;
      text-align: center;
      color: #fff !important; }
      .green .left.navigator .ui.menu .item .label {
        padding: 3%;
        font-size: 0.6rem;
        margin: auto;
        background: none;
        text-transform: uppercase;
        width: 100%;
        border: 0; }
      .green .left.navigator .ui.menu .item img {
        height: auto;
        width: 65%;
        cursor: pointer;
        margin: -3px auto 5px auto; }
    .green .left.navigator .ui.menu .item.active {
      border: 1px solid #5089a6;
      background-color: #5089a6; }

@media screen and (max-width: 1300px) {
  .green .left.navigator .ui.menu {
    background-color: #5baf95;
    margin: 0;
    padding: 0;
    width: 5vw;
    min-width: 65px;
    max-width: 100px;
    display: flex; }
  .green .left.navigator .label {
    display: none; } }

@media screen and (max-width: 1023px) {
  .green .left.navigator {
    display: none; }
    .green .left.navigator .label {
      display: none; } }

@media screen and (max-width: 1023px) {
  .green .left.navigator {
    display: none; }
    .green .left.navigator .label {
      display: none; } }

.featured.tabs {
  display: flex; }
  .featured.tabs .grid.desktop {
    flex-basis: 100%;
    padding: 0;
    margin: 0; }
    .featured.tabs .grid.desktop .cover {
      display: flex;
      flex-direction: column;
      background-size: cover;
      background-repeat: no-repeat !important;
      height: 100%;
      border: 1px solid #b8ccd8;
      border-top: 0;
      margin-top: 0 !important;
      padding: 10%;
      text-align: center;
      justify-content: flex-end; }
      .featured.tabs .grid.desktop .cover .entry-content,
      .featured.tabs .grid.desktop .cover .excerpt {
        display: flex;
        flex-direction: column;
        height: 100%; }
        .featured.tabs .grid.desktop .cover .entry-content blco p,
        .featured.tabs .grid.desktop .cover .excerpt blco p {
          margin-bottom: 0; }
      .featured.tabs .grid.desktop .cover .label {
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        line-height: 160%;
        margin: auto;
        margin-top: auto;
        margin-bottom: 5%;
        width: 150px;
        border: 1px solid #fff;
        color: #fff;
        background-color: transparent !important; }
        .featured.tabs .grid.desktop .cover .label .icon {
          margin-right: 4%; }
          .featured.tabs .grid.desktop .cover .label .icon:before {
            background: 20px 20px !important;
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjIuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIGZvY3VzYWJsZT0iZmFsc2UiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiCgkgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0ZGRkZGRjt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik01MDguNSw0NjguOUwzODcuMSwzNDcuNWMtMi4zLTIuMy01LjMtMy41LTguNS0zLjVoLTEzLjJjMzEuNS0zNi41LDUwLjYtODQsNTAuNi0xMzZDNDE2LDkzLjEsMzIyLjksMCwyMDgsMAoJUzAsOTMuMSwwLDIwOHM5My4xLDIwOCwyMDgsMjA4YzUyLDAsOTkuNS0xOS4xLDEzNi01MC42djEzLjJjMCwzLjIsMS4zLDYuMiwzLjUsOC41bDEyMS40LDEyMS40YzQuNyw0LjcsMTIuMyw0LjcsMTcsMGwyMi42LTIyLjYKCUM1MTMuMiw0ODEuMiw1MTMuMiw0NzMuNiw1MDguNSw0NjguOXogTTIwOCwzNjhjLTg4LjQsMC0xNjAtNzEuNi0xNjAtMTYwUzExOS42LDQ4LDIwOCw0OHMxNjAsNzEuNiwxNjAsMTYwUzI5Ni40LDM2OCwyMDgsMzY4eiIvPgo8L3N2Zz4K") !important;
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 100% 100% !important;
            display: block;
            height: 20px;
            width: 20px;
            content: ""; }
      .featured.tabs .grid.desktop .cover p {
        color: #fff; }
    .featured.tabs .grid.desktop .column {
      color: #fff;
      padding: 0 !important;
      margin: 0 !important; }
    .featured.tabs .grid.desktop .column.expanded {
      padding: 0px;
      border: 1px solid #dadada; }
      .featured.tabs .grid.desktop .column.expanded .ui.menu.tabbed.posts .label {
        color: #5baf95;
        position: relative;
        right: auto;
        left: auto;
        top: auto; }
        .featured.tabs .grid.desktop .column.expanded .ui.menu.tabbed.posts .label span {
          display: block;
          margin-top: 30px; }
      .featured.tabs .grid.desktop .column.expanded .segment {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0px 10px;
        height: 70px;
        border: 0px;
        border-radius: 0px; }
        .featured.tabs .grid.desktop .column.expanded .segment img {
          margin-left: 2%;
          height: 40px !important; }
        .featured.tabs .grid.desktop .column.expanded .segment .title {
          font-size: inherit;
          color: #fff;
          padding-top: 0; }
        .featured.tabs .grid.desktop .column.expanded .segment h2 {
          font-weight: 300;
          padding: 10px;
          margin: 0px; }
      .featured.tabs .grid.desktop .column.expanded .entry-content {
        color: #000;
        margin: 0px;
        padding: 4%; }
      .featured.tabs .grid.desktop .column.expanded .label {
        position: absolute;
        right: 15px;
        bottom: 15px;
        cursor: pointer;
        color: #fff;
        background-color: #5baf95; }
        .featured.tabs .grid.desktop .column.expanded .label .icon {
          background-color: #5baf95;
          position: relative !important;
          left: auto !important;
          top: auto !important;
          bottom: auto !important;
          right: auto !important;
          padding: 0px;
          margin-right: 2px; }
      .featured.tabs .grid.desktop .column.expanded .full-link {
        margin-top: 5vmin;
        width: 80%;
        padding: 2vmin;
        border-bottom: 1px solid #dadada;
        border-top: 1px solid #dadada; }

.inline.list {
  padding: 0px; }
  .inline.list .ui.grid {
    border-bottom: 1px solid #dadada;
    padding: 0px 0px 30px 0px;
    margin: 0px 0px 30px 0px; }
    .inline.list .ui.grid .column {
      padding: 0px; }
    .inline.list .ui.grid .ui.container.entry-content {
      padding: 0px 14px; }
    .inline.list .ui.grid .ui.label {
      height: auto;
      background-color: transparent;
      text-align: center;
      padding: 0px;
      margin: 0px; }
      .inline.list .ui.grid .ui.label img {
        height: auto !important;
        width: 100% !important;
        min-width: 30px;
        margin: 0px; }

.chart.container {
  margin: 0px;
  padding: 0px; }
  .chart.container .body {
    padding: 0px;
    margin: 0px; }
    .chart.container .body .ui.container.entry-content {
      padding: 0px !important;
      margin: 0px !important; }
    .chart.container .body svg text {
      font-size: 13px !important; }
  .chart.container .footnote {
    width: 100%;
    text-align: left;
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: auto;
    margin-bottom: 0px; }
    .chart.container .footnote .grid {
      width: 100%; }
    .chart.container .footnote .column {
      height: 40px;
      vertical-align: bottom;
      padding: 0px;
      margin: 0px; }
    .chart.container .footnote p {
      line-height: 40px;
      font-size: 13px;
      padding: 0px;
      margin-left: 10px;
      margin-right: 10px;
      display: flex; }
    .chart.container .footnote .button {
      font-size: 17px;
      background-color: #d6d6d6 !important;
      font-weight: 100;
      border: 1px solid;
      width: 157px !important;
      height: 40px !important;
      font-weight: 500;
      color: white !important;
      padding-left: 5px !important;
      padding-right: 2px !important;
      display: flex;
      align-items: center;
      justify-content: center; }
    .chart.container .footnote .button.active {
      background: #4f8ba5 !important; }
  .chart.container .button.download {
    cursor: pointer;
    font-size: 14px;
    background-color: transparent !important;
    font-weight: 100;
    padding: 0px !important;
    color: #5aade4 !important;
    border: 1px solid #5aade4;
    width: auto !important;
    height: 30px !important;
    padding: 0px 7px 0px 7px !important;
    margin-bottom: 15px !important; }
    .chart.container .button.download .download.icon:before {
      margin-left: 10px !important; }

.chart.tooltip {
  border-radius: 4px;
  opacity: 0.9;
  padding: 7px;
  font-size: 16px;
  color: #fff2f2; }

.reset-filters {
  margin-right: 9em;
  margin-top: 0.8em; }
  .reset-filters .ui.icon.left.labeled.button {
    background-color: #e0e0e0;
    color: #a95313 !important;
    font-size: 0.8em !important;
    padding: 0.8em !important; }
  .reset-filters .ui.labeled.icon.button > .icon {
    background-color: transparent !important;
    padding-left: 1em !important; }

.chart-filters {
  padding: 1em 0.8em 0.6em 0.8em !important;
  border-radius: 0.4em 0.4em 0em 0em; }
  .chart-filters .ui.labeled.icon.button > .icon {
    background-color: transparent;
    box-shadow: none;
    padding-left: 0.5em;
    font-size: 0.6em;
    opacity: 1; }
  .chart-filters .wp-block-column:not(:first-child) {
    margin-left: 0.1em; }
  .chart-filters .dropdown {
    background-color: #768798 !important;
    color: #ffffff !important;
    padding-left: 0.4em !important;
    padding-bottom: 4px !important;
    padding-top: 4px !important; }
    .chart-filters .dropdown .text {
      color: #ffffff;
      font-size: 0.8em !important;
      margin: 0.6em 0 0 0 !important; }
    .chart-filters .dropdown .item .text {
      color: #000000;
      font-size: 0.8em !important; }
  .chart-filters .visible.menu.transition {
    border: none;
    margin-top: 5px !important;
    position: absolute;
    padding: 10px;
    z-index: 1001; }
  .chart-filters .ui.label {
    border-radius: 0.4em !important; }

.chart-wrapper {
  background-color: #ffffff;
  border-top: none;
  border-radius: 0em 0em 0.4em 0.4em; }

.chart-separator {
  margin: 0; }

.see-more {
  margin-right: 70px;
  margin-top: -30px;
  position: relative;
  text-align: right; }
  .see-more button.ui.mini.button {
    background-color: transparent;
    border: none;
    color: #2271b1;
    text-decoration: underline; }

.carousel {
  width: 100%;
  padding: 0px; }
  .carousel .wp-block-cover {
    padding: 0px; }
  .carousel .wp-block-group {
    min-height: 555px;
    padding: 1rem 2rem; }
  .carousel .wp-block-columns .wp-block-column {
    padding: 0px;
    margin: 0px; }
  .carousel li {
    padding: 0px;
    min-height: 565px; }
    .carousel li .wp-block-buttons {
      padding: 10px 0px; }
      .carousel li .wp-block-buttons .wp-block-button {
        margin: 0px; }

.carousel__dot-group {
  margin: 0px auto auto;
  text-align: center; }
  .carousel__dot-group .carousel__dot {
    pointer-events: all !important;
    background-color: #bcc3e0;
    margin: 5px;
    border-radius: 100%;
    height: 15px;
    width: 15px;
    border: 0px !important; }
  .carousel__dot-group .carousel__dot:hover {
    background-color: #3e8e41; }
  .carousel__dot-group .carousel__dot:focus {
    outline: none;
    box-shadow: none; }
  .carousel__dot-group .carousel__dot:active {
    background-color: #3e8e41;
    box-shadow: none;
    transform: translateY(0px); }
  .carousel__dot-group .carousel__dot--selected {
    background-color: #3e53a6; }

.container.desktop h1 {
  font-weight: 400; }

.container.desktop h2 {
  font-weight: 400; }
  .container.desktop h2 .title {
    padding-top: 50px; }

.container.desktop p {
  font-family: "Open Sans", sans-serif; }

.container.green .wp-block-column figure,
.container.green .wp-block-column p {
  margin-block-end: 0;
  margin-block-start: 0em; }

.container.green .excerpt .entry-content {
  margin: 0; }

.container.section {
  margin-left: 0px;
  margin-bottom: 50px; }
  .container.section .ui.menu.header {
    margin: 0;
    background-color: #fff;
    border: 1px solid #dadada;
    background: #5baf95; }
    .container.section .ui.menu.header h1 {
      font-family: "Roboto", sans-serif;
      font-size: 2rem;
      font-weight: 300 !important;
      padding: 0;
      margin: 0; }
    .container.section .ui.menu.header h2 {
      font-family: "Roboto", sans-serif;
      font-size: 1.5rem !important;
      margin: 0;
      padding: 0;
      font-weight: 300 !important; }
    .container.section .ui.menu.header .item {
      padding: 1.5rem;
      background: #5baf95;
      width: 20vw;
      max-height: 160px !important;
      max-width: 191px !important; }
    .container.section .ui.menu.header .ui.image {
      margin: auto;
      width: 60%; }
    .container.section .ui.menu.header .header {
      padding: 2%;
      background: #fff;
      width: 100%;
      font-weight: 300;
      justify-content: left;
      align-items: center;
      display: flex;
      border-left: 1px solid #dadada; }
      .container.section .ui.menu.header .header > div {
        margin-left: 1.5vw; }
      .container.section .ui.menu.header .header p {
        line-height: 100%;
        padding: 0;
        margin: 0; }
  @media (max-width: 600px) {
    .container.section .ui.menu .item {
      display: none; } }
  .container.section .subsection {
    margin: 0 !important;
    padding: 1vw; }
    .container.section .subsection a {
      color: #3e9fde !important; }
    .container.section .subsection .grid.header {
      padding: 0.5vw;
      margin: 0; }
      .container.section .subsection .grid.header h2.title {
        text-transform: none;
        padding-left: 0;
        color: #5baf95;
        font-weight: 300;
        margin: 0; }
      .container.section .subsection .grid.header h3.subtitle {
        text-transform: none;
        font-size: 1.5rem;
        margin: 0;
        color: #66676d;
        font-weight: 300;
        padding-left: 0; }
      .container.section .subsection .grid.header .column,
      .container.section .subsection .grid.header .row {
        padding: 0;
        margin: 0; }
      .container.section .subsection .grid.header .column {
        min-height: 90px;
        border-bottom: 1px solid #dadada; }
      .container.section .subsection .grid.header .icon {
        width: 40px;
        height: 40px;
        min-width: 30px !important;
        min-height: 30px !important;
        content: "";
        margin-top: 15px; }
      @media (max-width: 779px) {
        .container.section .subsection .grid.header .column:nth-child(1) {
          display: none; }
        .container.section .subsection .grid.header h2.title {
          font-size: 1.5em; }
        .container.section .subsection .grid.header h3.subtitle {
          font-size: 1.3em; } }

.intro {
  margin: 50px auto auto;
  padding: 0; }
  .intro .entry-content {
    padding: 0 !important;
    margin: 0; }
    .intro .entry-content .has-intermediate-font-size {
      font-size: 1.8rem;
      line-height: 135%;
      padding-bottom: 35px;
      padding-top: 25px; }
  @media screen and (max-width: 781px) and (min-width: 600px) {
    .intro .wp-block-columns {
      margin-bottom: 10vh !important; }
    .intro .wp-block-column:nth-child(1) {
      flex-basis: calc(20% - 5px) !important;
      flex-grow: 0; }
    .intro .wp-block-column:nth-child(2) {
      flex-basis: calc(80% - 16px) !important;
      flex-grow: 0; } }
  @media (max-width: 599px) {
    .intro .wp-block-column:nth-child(2) {
      text-align: center;
      flex-grow: 0; } }
  .intro .wp-block-columns {
    margin: 0; }
    .intro .wp-block-columns .wp-block-column {
      margin: 0;
      padding: 0.3vw; }
      .intro .wp-block-columns .wp-block-column p {
        margin: 0.3em; }
      .intro .wp-block-columns .wp-block-column figure {
        margin-block-end: 0;
        margin-block-start: 0.3em; }

.cost .cost-gender-label {
  line-height: 90%; }

.footer {
  background-color: #2B2F31 !important;
  height: var(--footer-height);
  padding: 0px;
  margin: 0px;
  width: 100%;
  position: absolute; }

.footer-menu {
  position: absolute;
  right: 50px !important;
  max-width: 550px !important;
  padding-top: 16px; }
  .footer-menu a {
    color: white !important; }
  .footer-menu a:hover {
    color: #76a234 !important; }

.wp-block-wp-react-lib-components-newsletter {
  width: 100%;
  padding: 10px; }

.wp-react-lib.newsLetter {
  text-align: center;
  max-width: 500px;
  height: 60px;
  margin: 1rem auto; }
  .wp-react-lib.newsLetter .ui.message {
    margin: 0px; }
    .wp-react-lib.newsLetter .ui.message p {
      padding: 0px;
      margin: 0px;
      line-height: 5px; }
  .wp-react-lib.newsLetter .wp-react-lib.newsLetter.form {
    padding: 0px;
    height: 42px;
    display: flex;
    justify-content: left;
    margin-bottom: 3px; }
    .wp-react-lib.newsLetter .wp-react-lib.newsLetter.form .input {
      flex-grow: 1;
      height: 40px; }
    .wp-react-lib.newsLetter .wp-react-lib.newsLetter.form .ui.button {
      height: 40px;
      line-height: 70%;
      margin-left: 10px; }

.wp-react-lib.showcase {
  width: 100% !important; }
  .wp-react-lib.showcase .input,
  .wp-react-lib.showcase textarea,
  .wp-react-lib.showcase .listbox,
  .wp-react-lib.showcase .dropdown {
    width: 100%;
    font-size: 0.9rem;
    min-height: 20px;
    height: 40px; }
  .wp-react-lib.showcase .btn-reset {
    background-color: #66676d;
    color: #ffffff;
    margin-right: 20px; }
  .wp-react-lib.showcase textarea {
    height: 100px;
    font-size: 0.8em;
    padding: 10px; }
    .wp-react-lib.showcase textarea::placeholder, .wp-react-lib.showcase textarea::-webkit-input-placeholder {
      color: rgba(191, 191, 191, 0.87); }
  .wp-react-lib.showcase .validated .field.error iframe {
    background-color: #fff6f6;
    border: 1px solid #e0b4b4; }
  .wp-react-lib.showcase .validated .field.error input {
    background-color: #98511a; }
  .wp-react-lib.showcase .upload.files {
    text-align: right; }
    .wp-react-lib.showcase .upload.files.error {
      background-color: transparent; }
    .wp-react-lib.showcase .upload.files .ui.green.label,
    .wp-react-lib.showcase .upload.files .ui.green.labels .label {
      background-color: #5baf95 !important; }
    .wp-react-lib.showcase .upload.files .ui.button {
      font-size: 0.8rem;
      margin-top: 10px;
      margin-right: 0; }
  .wp-react-lib.showcase .form-buttons {
    padding-top: 30px; }
    .wp-react-lib.showcase .form-buttons button {
      width: 100px; }
  .wp-react-lib.showcase .dropzone {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.9em;
    padding: 10px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: rgba(191, 191, 191, 0.87);
    outline: none;
    transition: border 0.24s ease-in-out 0s; }
    .wp-react-lib.showcase .dropzone p {
      font-size: 0.9em;
      padding-bottom: 15px; }
    .wp-react-lib.showcase .dropzone ul {
      list-style: none;
      text-align: left; }
      .wp-react-lib.showcase .dropzone ul li {
        margin: 4px; }
  .wp-react-lib.showcase .upload.files.error .dropzone {
    background-color: #ffffff;
    border: none;
    color: #9f3a38; }

.notice,
div.error,
div.updated {
  background-color: none;
  border: none;
  border-radius: 5px;
  margin: 0;
  padding: 0; }

.ui.input.error > input {
  background-color: transparent;
  border: none; }

.notice,
div.error,
div.updated {
  background-color: none; }

.ui.search.dropdown > .text {
  top: -3px; }

.ui.negative.message p {
  font-family: "Roboto", sans-serif;
  font-size: 0.9em; }

.ui.success.message p {
  font-family: "Roboto", sans-serif;
  font-size: 0.9em; }

.ui.selection.dropdown.error {
  background-color: #ffffff;
  border-color: transparent; }

.download .download-header .ui.container.entry-content {
  padding-bottom: 10px !important;
  padding-top: 10px !important; }

.download .wp-block-columns {
  margin-bottom: 15px; }

.download .download.area {
  display: flex; }
  .download .download.area .ui.container.entry-content {
    padding: 0; }
  .download .download.area i.icon.question.circle {
    color: red;
    font-size: 0.8em;
    padding: 7px; }
    .download .download.area i.icon.question.circle:before {
      border-left: 2px solid red;
      display: block;
      float: left;
      height: 20px;
      line-height: 20px;
      margin-left: 8px;
      margin-top: -5px;
      width: 45px; }

.download .button.download {
  background-color: transparent;
  color: #76a234;
  font-size: 14px;
  font-weight: 600;
  z-index: 1000;
  border: 0;
  margin-right: 0; }

.download .download-icon:before {
  top: 1px;
  margin-left: 0;
  color: #76a234;
  cursor: pointer;
  padding: 7px 9px 9px 15px;
  position: relative;
  z-index: 999;
  background-color: transparent !important;
  cursor: pointer;
  z-index: 90 !important; }

.download .active .download-icon:before {
  padding-bottom: 17px;
  border-bottom: 0px;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important; }

.download .dropdown.icon {
  display: none; }

.download .dropdown.download .visible.menu.transition {
  backface-visibility: hidden;
  position: absolute;
  left: auto;
  top: 36px;
  right: 9px;
  padding: 15px 20px;
  box-shadow: none;
  border-color: #51AB8D;
  border-radius: 0 !important;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  font-size: 16px;
  color: #51AB8D;
  font-weight: 600;
  z-index: 80 !important; }

.download .dropdown.download .visible.menu.transition .item {
  padding: 12px 0px 0px 0px !important;
  background: none !important; }

.download .dropdown.download .visible.menu.transition .item input {
  margin-top: 0px; }

.download .dropdown.download input[type=radio] {
  border: 1px solid #66676d;
  font-size: 10px;
  height: 14px;
  width: 14px;
  min-height: 6px;
  min-width: 6px;
  margin: 5px 5px 2px 0px; }

.download .dropdown.download input[type=radio]:checked::before {
  content: "";
  background-color: #31ba1a;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin: 3px; }

.download .dropdown.download label {
  font-size: 16px;
  color: #66676d; }

.tcdi.download.has-title .download.title {
  padding: 0;
  margin: 0px;
  float: left; }
  .tcdi.download.has-title .download.title p {
    line-height: 125%; }

.tcdi.download.has-title .ui.grid, .tcdi.download.has-title .column {
  padding: 0px;
  margin: 0px; }
  .tcdi.download.has-title .ui.grid .editing, .tcdi.download.has-title .column .editing {
    margin-top: -1rem !important; }

.tcdi.download.has-title .ui.grid {
  margin-bottom: -5px;
  margin-top: -5px; }

.tcdi.download.light .wrapper {
  border: 1px solid #0692fe;
  padding: 3px 5px;
  border-radius: 5px;
  float: right; }

.tcdi.download.light .map.wrapper {
  border: none;
  float: none;
  overflow: hidden; }
  @media print {
    .tcdi.download.light .map.wrapper {
      margin-left: 20px; } }

.tcdi.download.light [data-tooltip]:after {
  font-size: 0.8rem; }

.tcdi.download.light [data-tooltip]:before {
  bottom: 23px; }

.tcdi.download.light .ui.button.download {
  border: 0px;
  font-weight: 300;
  padding: 5px;
  background-color: #FFF;
  color: #0692fe;
  margin-right: 0px; }
  .tcdi.download.light .ui.button.download:hover {
    color: #046fbe; }

.tcdi.download.light .dropdown.download {
  padding-right: 10px; }

.tcdi.download.light .download-icon:before {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border: 0px;
  border-left: 1px solid #0692fe;
  color: #0692fe;
  height: 20px;
  margin-left: 3px;
  padding: 0 4px 0 9px; }

.tcdi.download.light .download-icon:hover:before {
  color: #046fbe; }

.tcdi.download.light .dropdown.download .visible.menu.transition {
  border: 1px solid #0692fe;
  top: 30px;
  right: -5px;
  font-weight: 400;
  color: #0692fe;
  border-radius: 5px; }

.ui.top.left.popup.title-popup {
  background-color: #f7f7f7;
  padding: 20px; }
  .ui.top.left.popup.title-popup:before {
    background: #f7f7f7; }

.dropdown.download::before {
  margin-left: 9px; }

.cashew-structure-header {
  height: 3.6em;
  background-color: #f6faf5;
  border-top: 1px solid #e6e7ec;
  padding: 1em; }

.cashew-structure-title {
  padding-bottom: 0em;
  padding-left: 0.4em; }

.cashew-structure-content {
  padding-left: 0.6em; }
  .cashew-structure-content div:last-child {
    margin-bottom: 0em; }

.cashew-structure-date {
  font-weight: bold;
  font-size: 0.8em;
  margin-top: 0em; }

:root {
  --header-height: 90px;
  /* Update this value to change header's height */
  --footer-height: 227px !important;
  /* Update this value to change footer's height */ }

.body.parts {
  padding: 0px;
  margin: auto; }
  .body.parts text.label {
    cursor: pointer;
    font-size: 14px;
    fill: #66676d;
    font-weight: bold; }
  .body.parts text.label:hover {
    fill: #5089a6; }
  .body.parts text.title {
    cursor: pointer;
    font-size: 16px;
    fill: #3e53a6;
    font-weight: bold; }
  .body.parts circle,
  .body.parts line {
    stroke: #5089a6; }
  .body.parts circle {
    fill: #5089a6; }
  .body.parts svg {
    margin: auto;
    width: 100%; }

:root {
  --header-height: 90px;
  /* Update this value to change header's height */
  --footer-height: 227px !important;
  /* Update this value to change footer's height */ }

.wp-react-lib.dashboard.gallery .item .preview {
  border: 2px solid #dadada;
  border-radius: 5px;
  height: 100%;
  padding: 0px; }
  .wp-react-lib.dashboard.gallery .item .preview .wp-block-columns {
    bottom: 10px;
    position: absolute;
    z-index: 1; }
  .wp-react-lib.dashboard.gallery .item .preview .wp-block-cover {
    margin-bottom: 60px; }
  .wp-react-lib.dashboard.gallery .item .preview .ui.container.entry-content,
  .wp-react-lib.dashboard.gallery .item .preview figure,
  .wp-react-lib.dashboard.gallery .item .preview img,
  .wp-react-lib.dashboard.gallery .item .preview .wp-block-image {
    width: 100% !important;
    margin-bottom: 0.4rem;
    padding: 0px; }
  .wp-react-lib.dashboard.gallery .item .preview .wp-block-image .aligncenter {
    display: block; }
  .wp-react-lib.dashboard.gallery .item .preview p {
    padding-bottom: 0.4rem;
    font-weight: 400; }
  .wp-react-lib.dashboard.gallery .item .preview .wp-block-columns {
    padding: 0px 1rem; }

/* 3 Less.js Mixin's for making 3 concentric semi-transparent circles */
/* The primary circle with four sizes (small, normal, medium, large) */
.leaflet-control.leaflet-bar:not(.leaflet-control-zoom) {
  background-color: #ffffff;
  padding: 1em;
  margin-bottom: 2em; }

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.6em; }

.square {
  width: 15px;
  height: 15px;
  margin-right: 8px;
  display: inline-block; }

.map-adm-icon {
  color: #222;
  font-weight: bold;
  text-align: center; }

.map-circle {
  background: #ff7777;
  color: #fff;
  font-weight: bold;
  text-align: center;
  text-shadow: 0 0 3px #003;
  border-radius: 50%;
  -moz-border-radius: 50%;
  line-height: 20px;
  width: 22px;
  margin-left: 19px;
  line-height: 22px; }
  .map-circle:before {
    background: rgba(205, 95, 95, 0.25);
    content: ".";
    z-index: -1;
    border-radius: 50%;
    -moz-border-radius: 50%;
    position: absolute;
    margin-left: 0;
    left: 0px;
    top: -3px;
    line-height: 26px;
    width: 28px;
    margin-left: 16px; }
  .map-circle:after {
    background: rgba(205, 95, 95, 0.1);
    content: ".";
    z-index: -3;
    border-radius: 50%;
    -moz-border-radius: 50%;
    position: absolute;
    display: inline-block;
    margin-left: 0;
    left: 0px;
    top: -5px;
    line-height: 32px;
    width: 34px;
    margin-left: 13px; }

.legend-sample .map-circle:before {
  display: none; }

.legend-sample .map-circle:after {
  display: none; }

.map-circle.smaller {
  line-height: 12px;
  width: 14px;
  margin-left: 23px;
  line-height: 14px; }
  .map-circle.smaller:before {
    margin-left: 0;
    left: 0px;
    top: -3px;
    line-height: 18px;
    width: 20px;
    margin-left: 20px;
    line-height: 20px; }
  .map-circle.smaller:after {
    margin-left: 0;
    left: 0px;
    top: -5px;
    width: 26px;
    margin-left: 17px;
    line-height: 24px; }

.map-circle.medium {
  line-height: 24px;
  width: 26px;
  margin-left: 17px; }
  .map-circle.medium:before {
    margin-left: 0;
    left: 0px;
    top: -3px;
    line-height: 30px;
    width: 32px;
    margin-left: 14px; }
  .map-circle.medium:after {
    margin-left: 0;
    left: 0px;
    top: -5px;
    line-height: 36px;
    width: 38px;
    margin-left: 11px; }

.map-circle.large {
  line-height: 38px;
  width: 40px;
  margin-left: 10px; }
  .map-circle.large:before {
    margin-left: 0;
    left: 0px;
    top: -3px;
    line-height: 44px;
    width: 46px;
    margin-left: 7px; }
  .map-circle.large:after {
    margin-left: 0;
    left: 0px;
    top: -5px;
    line-height: 50px;
    width: 52px;
    margin-left: 4px; }

.map-circle.invert {
  border-color: #ff7777;
  background: #cd5f5f; }

.map-circle-label {
  font-size: 0.8em;
  background: rgba(255, 255, 255, 0.5);
  padding: 1px;
  border-radius: 2px; }

.view-more {
  background-color: #ffffff;
  border: none;
  font-weight: bold;
  height: auto;
  margin-bottom: 10px;
  outline: none;
  padding: 10px;
  text-align: center;
  width: auto !important; }
  .view-more a,
  .view-more a:hover {
    color: #2789d9;
    cursor: pointer;
    text-decoration: underline;
    width: auto !important; }

.leaflet-touch .leaflet-bar {
  border: none; }

.map-control {
  top: 65px; }
  .map-control a {
    cursor: pointer; }
  .map-control img {
    height: 26px;
    width: 26px;
    background: white;
    border-radius: 4px; }

.legend {
  background-color: #ffffff;
  color: #304f69;
  line-height: 140%;
  padding: 0.5em 1em 1.1em 1em; }
  .legend .separator {
    color: #b5b5b5;
    font-weight: 300;
    padding-left: 1em;
    padding-right: 1em; }
  .legend .circle {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin-right: 8px;
    background-color: none;
    display: inline-block; }
  .legend .square {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    display: inline-block; }
  .legend .circle.association {
    background-color: #dc9456 !important; }
  .legend .circle.male {
    background-color: #509cdc !important; }
  .legend .circle.female {
    background-color: #db4f4f !important; }
  .legend .circle.male.cluster {
    background-color: #4c9aff !important; }
  .legend .circle.female.cluster {
    background-color: #ffab00 !important; }
  .legend .circle.total {
    background-color: #68945c !important; }

.leaflet-popup-content-wrapper {
  border-radius: 0px !important; }

.farm-location-popup .farm-location-popup-title {
  color: #005ce7;
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0px; }

.farm-location-popup .farm-location-popup-list {
  font-style: italic; }

.ui.labeled.icon.button {
  padding-bottom: 1em;
  padding-left: 2em !important;
  padding-right: 1em !important;
  padding-top: 1em;
  border: 1px solid transparent; }
  .ui.labeled.icon.button:hover {
    background-color: #cccccc !important; }

.reset-filters {
  margin-right: 9em;
  margin-top: 0.8em; }
  .reset-filters .ui.icon.left.labeled.button {
    background-color: #e0e0e0;
    color: #a95313 !important;
    font-size: 0.8em !important;
    padding: 0.8em !important; }
  .reset-filters .ui.labeled.icon.button > .icon {
    background-color: transparent !important;
    padding-left: 2em !important; }

.map-filters {
  padding: 1em 0.8em !important;
  border-radius: 0.4em 0.4em 0em 0em; }
  .map-filters .ui.labeled.icon.button > .icon {
    background-color: transparent;
    box-shadow: none;
    padding-left: 0.5em;
    font-size: 0.6em;
    opacity: 1; }
  .map-filters .wp-block-column:not(:first-child) {
    margin-left: 0.1em; }
  .map-filters .dropdown {
    background-color: #768798 !important;
    color: #ffffff !important;
    padding-left: 0.4em !important;
    padding-bottom: 4px !important;
    padding-top: 4px !important; }
    .map-filters .dropdown .text {
      color: #ffffff;
      font-size: 0.8em !important;
      margin: 0.6em 0 0 0 !important; }
    .map-filters .dropdown .item .text {
      color: #000000;
      font-size: 0.8em !important; }
  .map-filters .visible.menu.transition {
    border: none;
    margin-top: 5px !important;
    position: absolute;
    padding: 10px;
    z-index: 1001; }
  .map-filters .ui.label {
    border-radius: 0.4em !important; }

.map-wrapper {
  border-top: none;
  border-radius: 0em 0em 0.4em 0.4em; }
  .map-wrapper .leaflet-container {
    border-left: 1px solid #e6eaee;
    border-right: 1px solid #e6eaee; }

.map-separator {
  margin-top: 0.4em; }

.cluster-marker {
  background-clip: padding-box;
  border-radius: 20px; }

.cluster-marker div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px;
  font-weight: bold; }

.cluster-marker span {
  line-height: 30px; }

.female-farm-group-marker-legend, .female-farm-group-marker div {
  background-color: #ffab00; }

.male-farm-group-marker-legend, .male-farm-group-marker div {
  background-color: #4c9aff; }

.female-farm-association-group-marker-legend, .female-farm-association-group-marker div {
  background-color: #e19702; }

.male-farm-association-group-marker-legend, .male-farm-association-group-marker div {
  background-color: #4267a4; }

.female-individual-farm-group-marker-legend, .female-individual-farm-group-marker div {
  background-color: #ffab00; }

.male-individual-farm-group-marker-legend, .male-individual-farm-group-marker div {
  background-color: #4c9aff; }

.adm-name div {
  width: 90px;
  text-align: center; }

.adm-name span {
  background-color: #ffffff;
  padding: 0.1em 0.4em; }

.global-production-map-legend {
  background-color: #FFFFFF;
  font-size: 0.7em;
  padding: 0.5em; }
  .global-production-map-legend table, .global-production-map-legend th, .global-production-map-legend td {
    border: 0.2em solid #FFFFFF; }
  .global-production-map-legend td {
    padding: 0.1em 0.4em; }
  .global-production-map-legend tr:nth-child(1) td:nth-child(1) {
    background-color: #D9DDD9;
    padding: 0.2em; }
  .global-production-map-legend tr:nth-child(1) td:nth-child(3) {
    background-color: #C2E699;
    padding: 0.2em; }
  .global-production-map-legend tr:nth-child(2) td:nth-child(1) {
    background-color: #A95313; }
  .global-production-map-legend tr:nth-child(2) td:nth-child(3) {
    background-color: #78C679; }
  .global-production-map-legend tr:nth-child(3) td:nth-child(1) {
    background-color: #FFFFCC; }
  .global-production-map-legend tr:nth-child(3) td:nth-child(3) {
    background-color: #238443; }

.filter-container {
  margin-bottom: 0em; }
  .filter-container .visible.menu.transition {
    z-index: 1001;
    padding-top: 0.2em; }
  .filter-container .wp-block-columns {
    margin-bottom: 1em; }

.filter-container.wp-block-columns.has-background {
  padding: 1em;
  padding-bottom: 0em; }

.filter .dropdown {
  min-width: 260px;
  min-height: 42px;
  height: 42px !important; }

.filter .button {
  width: 100%;
  height: auto; }

.dropdown-popup {
  border-radius: 0 0 0.3em 0.3em !important;
  padding: 0 0 0 0 !important;
  min-width: 12.2em !important; }

.dropdown-search {
  padding: 0.4em;
  width: 100% !important; }

.dropdown-selection {
  font-size: 0.7em !important;
  margin: 0.2em 0.6em;
  width: 100%; }

.dropdown-options {
  margin: 0.2em 0.5em; }

.dropdown-options-title {
  margin-bottom: 0.2em; }

.dropdown-options-list {
  margin-top: 0 !important;
  max-height: 7.6em;
  overflow: auto; }

.dropdown-options-option {
  font-size: 0.7em !important; }

.dropdown-selection-none {
  color: #128d5e;
  cursor: pointer; }

.dropdown-popup.ui.bottom.left.popup:before {
  display: none; }

.dropdown-button {
  background-color: #768798 !important;
  color: #ffffff !important;
  font-size: 0.8em !important;
  height: 42px !important;
  padding: 0 !important;
  text-align: left !important; }

.dropdown-apply {
  background: #128d5e !important;
  color: #ffffff !important;
  font-size: 0.7em !important;
  margin: 0 0.6em 0.6em 0.6em !important; }

.separator {
  color: #b5b5b5;
  font-weight: 300;
  padding-left: 1em;
  padding-right: 1em; }

[data-tooltip]:after {
  font-size: .78571429rem !important; }

.last-update-date {
  font-size: 0.8em; }

.production-amount {
  font-size: 1.2em;
  color: #116282;
  text-align: center; }

.production-details {
  padding-top: 0.4em; }

.production-year {
  color: #159ad7;
  font-size: 0.8em;
  float: right; }

.production-source {
  color: #469343;
  font-size: 0.7em;
  font-weight: bold;
  font-style: italic;
  float: left;
  padding-top: 1.6em; }

.production-source.white {
  color: #ffffff; }

.country-flag-name {
  padding: 0.4em !important; }

.country-flag {
  padding-right: 0 !important; }
  .country-flag img {
    max-height: 2.6em; }

.country-name {
  padding-left: 0.4em !important; }

.country-link {
  font-size: 0.8em; }

.no-data {
  background-color: #ffffff;
  position: relative;
  top: 50% !important; }

.no-data-wrapper {
  padding-top: 0em; }

.no-data-icon {
  max-height: 4em; }

.no-data-message {
  font-size: 1.2em;
  margin-top: 0; }

.partners-wrapper {
  background-color: #ffffff !important;
  padding: 1em;
  margin: 0 !important; }
  .partners-wrapper .partners-image {
    margin-bottom: 0.8em !important; }
  .partners-wrapper .partners-info .partners-info-description {
    color: #373d4b !important;
    margin-bottom: 0.8em !important; }
  .partners-wrapper .partners-info .partners-info-read-more {
    color: #373d4b !important; }

.partners-navigation {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.featured-partner-wrapper {
  background-color: #ffffff !important;
  padding: 1em;
  margin: 0 !important; }
  .featured-partner-wrapper .featured-partner-info {
    padding-left: 0 !important; }
    .featured-partner-wrapper .featured-partner-info .featured-partner-info-title {
      color: #354052 !important;
      margin-bottom: 0.2em !important; }
    .featured-partner-wrapper .featured-partner-info .featured-partner-info-heading {
      color: #a95313 !important;
      margin-bottom: 0.4em !important; }
    .featured-partner-wrapper .featured-partner-info .featured-partner-info-description {
      color: #373d4b !important;
      margin-bottom: 0.8em !important; }
    .featured-partner-wrapper .featured-partner-info .featured-partner-info-read-more {
      color: #373d4b !important; }

.resources-wrapper {
  padding: 0 !important;
  margin: 0 !important; }
  .resources-wrapper.ui.grid {
    margin-right: -0.8em !important; }
    .resources-wrapper.ui.grid .row {
      padding-bottom: 0; }
      .resources-wrapper.ui.grid .row .column {
        padding: 0 0.8em 0 0; }
        .resources-wrapper.ui.grid .row .column .resources-info {
          display: flex;
          flex-direction: column;
          margin: 0 0 0 0;
          background-color: #ffffff !important;
          border: #E8E9E9 solid 1px;
          padding: 0.8em;
          border-radius: 0.2em; }
          .resources-wrapper.ui.grid .row .column .resources-info p {
            margin-top: 0;
            margin-bottom: 0; }
          .resources-wrapper.ui.grid .row .column .resources-info .resources-info-heading {
            color: #b26b38 !important;
            font-weight: bold;
            font-style: italic;
            padding-bottom: 0 !important;
            margin-bottom: 0 !important; }
          .resources-wrapper.ui.grid .row .column .resources-info .resources-info-title {
            color: #000000 !important;
            font-weight: bold;
            padding-bottom: 0 !important;
            margin-bottom: 0 !important; }
          .resources-wrapper.ui.grid .row .column .resources-info .resources-info-description {
            color: #373d4b !important; }
          .resources-wrapper.ui.grid .row .column .resources-info .resources-info-read-more-wrapper {
            padding-top: 0.4em !important;
            margin-top: auto; }
            .resources-wrapper.ui.grid .row .column .resources-info .resources-info-read-more-wrapper .resources-info-read-more {
              color: #178f62 !important; }

.resources-navigation {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.resources-dropdown-options-list {
  margin-top: 0 !important;
  max-height: 7.6em;
  overflow: auto;
  font-size: 0.7em !important; }

.resources-dropdown-options-option {
  font-size: 1em !important; }

.ui.bottom.left.popup:before {
  display: none !important; }

.resources-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .resources-menu.ui.grid {
    margin-bottom: 0;
    margin-top: 0;
    flex-wrap: wrap;
    align-content: space-between; }
  .resources-menu .resources-resources-dropdown-button {
    background-color: transparent;
    font-size: 0.8em !important;
    height: 100% !important;
    padding: 0 !important;
    text-align: left !important;
    color: #304F69 !important;
    font-weight: 400;
    flex: 0 0;
    white-space: nowrap;
    flex-direction: column;
    margin: 0.8em 30px 0.8em 0; }
  .resources-menu .resources-resources-dropdown-button:hover, .resources-menu .resources-resources-dropdown-button:focus {
    background-color: transparent; }
  .resources-menu .resources-resources-dropdown-button .separator {
    font-weight: 300;
    padding-left: 0.1em;
    padding-right: 0.4em;
    float: right;
    margin-right: -20px; }
  .resources-menu .resources-resources-dropdown-button:last-of-type .separator {
    display: none; }

.resources-reset .ui.icon.left.labeled.button {
  background-color: #178f62 !important;
  color: #ffffff !important;
  font-size: 0.8em !important;
  padding: 0.8em !important; }

.resources-reset .ui.labeled.icon.button > .icon {
  background-color: transparent !important;
  padding-left: 2em !important; }

.resources-subject-checked {
  color: #ffffff !important;
  background-color: #b26b38 !important;
  font-weight: bold;
  padding: 0.6em !important;
  border-radius: 0.2em; }

.resources-filters-menu {
  font-size: 0.8em !important;
  padding-top: 0em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .resources-filters-menu .text {
    font-size: 0.8em !important; }

.latest-resources-primary-wrapper {
  background-color: #188F62 !important;
  border-radius: 0.2em;
  color: #ffffff;
  padding: 0 !important;
  margin: -1rem 0 -1.4em 0 !important; }
  .latest-resources-primary-wrapper .latest-resources-primary-image-wrapper {
    padding: 1.4rem !important; }
  .latest-resources-primary-wrapper .latest-resources-primary-image {
    width: 100% !important; }
  .latest-resources-primary-wrapper .latest-resources-primary-date-wrapper {
    padding: 1.4rem 1.4rem 0 1.4rem !important; }
  .latest-resources-primary-wrapper .latest-resources-primary-title-wrapper {
    padding: 0 1.4rem !important; }
  .latest-resources-primary-wrapper .latest-resources-primary-title {
    font-size: 1.4em; }
  .latest-resources-primary-wrapper .latest-resources-primary-link-wrapper {
    padding: 0.2em 1.4rem !important; }
  .latest-resources-primary-wrapper .latest-resources-primary-link-wrapper a {
    color: #ffffff;
    font-size: 0.8em;
    font-weight: bold; }

.latest-resources-secondary-wrapper {
  border-radius: 0.2em;
  color: #ffffff;
  padding: 0 !important;
  margin: 0 !important;
  height: 50% !important;
  opacity: 0.8;
  z-index: 10;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-end; }
  .latest-resources-secondary-wrapper .latest-resources-secondary-image {
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    position: absolute; }
  .latest-resources-secondary-wrapper .latest-resources-secondary-date-wrapper {
    padding-bottom: 0.2em !important; }
  .latest-resources-secondary-wrapper .latest-resources-secondary-title-wrapper {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .latest-resources-secondary-wrapper .latest-resources-secondary-title {
    font-size: 0.9em; }
  .latest-resources-secondary-wrapper .latest-resources-secondary-link-wrapper {
    padding-top: 0.2em !important;
    padding-bottom: 10px !important; }
  .latest-resources-secondary-wrapper .latest-resources-secondary-link-wrapper a {
    color: #ffffff;
    font-size: 0.7em;
    font-weight: bold; }

.latest-resources-secondary-wrapper-1 {
  background-color: #D6C826 !important;
  margin-left: -0.4em !important;
  margin-right: 0.4em !important;
  margin-bottom: 0.4em !important; }

.latest-resources-secondary-wrapper-2 {
  background-color: #188F62 !important; }

.latest-resources-secondary-wrapper-3 {
  background-color: #575757 !important;
  margin-left: -0.4em !important;
  margin-right: 0.4em !important; }

.latest-resources-secondary-wrapper-4 {
  background-color: #A7541A !important; }

* {
  line-height: normal; }

h1.type {
  font-size: 1rem;
  padding: 0;
  margin: 0px;
  color: #5089a6;
  font-family: Roboto, sans-serif; }

.wp-block-columns.compacted {
  padding: 2rem; }

.development, .scoring {
  padding: 1rem;
  width: 1400px !important; }
  .development p, .scoring p {
    font-size: 12px; }
  .development .label.basic, .scoring .label.basic {
    border: 0px; }
  .development .ui.grid > .column:not(.row), .development .ui.grid > .row > .column, .scoring .ui.grid > .column:not(.row), .scoring .ui.grid > .row > .column {
    font-size: 11px;
    padding: .4rem; }
  .development .ui.grid > .row, .scoring .ui.grid > .row {
    padding: .2rem; }
  .development textarea, .scoring textarea {
    font-size: 12px;
    height: 60px;
    width: 95%;
    border: 1px solid #CCC; }
  .development .ui.dropdown, .scoring .ui.dropdown {
    width: 95%;
    font-size: 12px; }
    .development .ui.dropdown .label, .scoring .ui.dropdown .label {
      width: 100%;
      font-size: 12px;
      background-color: #eee; }
  .development .ui.selection.dropdown .menu > .item, .scoring .ui.selection.dropdown .menu > .item {
    padding: 0.4rem !important;
    font-size: 12px; }
    .development .ui.selection.dropdown .menu > .item .text, .scoring .ui.selection.dropdown .menu > .item .text {
      display: block;
      padding: 0.4rem !important; }
    .development .ui.selection.dropdown .menu > .item .search, .scoring .ui.selection.dropdown .menu > .item .search {
      border: 1px solid #CCC; }
  .development .ui.rating.score-5 i.active, .scoring .ui.rating.score-5 i.active {
    color: #62e88b !important; }
  .development .ui.rating.score-4 i.active, .scoring .ui.rating.score-4 i.active {
    color: #ace862 !important; }
  .development .ui.rating.score-3 i.active, .scoring .ui.rating.score-3 i.active {
    color: #d3f379 !important; }
  .development .ui.rating.score-2 i.active, .scoring .ui.rating.score-2 i.active {
    color: #e89a62 !important; }
  .development .ui.rating.score-1 i.active, .scoring .ui.rating.score-1 i.active {
    color: #e86262 !important; }
